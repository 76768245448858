import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class CommonService {
    constructor(private http: HttpClient) { }

    listUsers(data): Observable<any> {
        return this.http.post(`${environment.api}admin/listUsers`, data);
    }

    userBlock(data): Observable<any> {
        return this.http.post(`${environment.api}admin/blockUser`, data);
    }

    userUnblock(data): Observable<any> {
        return this.http.post(`${environment.api}admin/unBlockUser`, data);
    }

    logout(): Observable<any> {
        return this.http.get(`${environment.api}admin/logout`, {});
    }

    _getAllNationalities(): Observable<any> {
        return this.http.get(`${environment.api}common/listCountries`, {});
    }

    _getUserDetails(guid): Observable<any> {
        return this.http.get(`${environment.api}admin/getUser?guid=${guid}`);
    }
    
    _getUserTransaction(data): Observable<any> {
        return this.http.post(`${environment.api}admin/getUserTransaction?`+data,{});
    }
    
    listLicence(data): Observable<any> {
        return this.http.post(`${environment.api}license/list`, data);
    }
    _getPermissionList(): Observable<any> {
        return this.http.get(`${environment.api}license/accessPermissionsList`, {});
    }
    _addOrganisation(data): Observable<any> {
        return this.http.post(`${environment.api}license/create`, data);
    }
    _addOrganisationUploadCsv(data): Observable<any> {
        return this.http.post(`${environment.api}license/updateLicenseFromCsv`, data);
    }
    _deleteOrganisation(data): Observable<any> {
        return this.http.post(`${environment.api}license/delete`, data);
    }
    _editOrganisation(data): Observable<any> {
        return this.http.post(`${environment.api}license/update`, data);
    }
    _createUsers(data): Observable<any> {
        return this.http.post(`${environment.api}license/createUsers`, data);
    }
    _editUsers(data): Observable<any> {
        return this.http.post(`${environment.api}license/updateUsers`, data);
    }
    _getCorporateUserDetails(id): Observable<any> {
        return this.http.get(`${environment.api}license/view?id=${id}`);
    }
    _getSubscriptionList(data): Observable<any> {
        return this.http.get(`${environment.api}subscription/list?`+data);
    }
    _addSubscription(data): Observable<any> {
        return this.http.post(`${environment.api}subscription/create`, data);
    }
    _editSubscription(data): Observable<any> {
        return this.http.post(`${environment.api}subscription/update`, data);
    }
    _getSubscriptionDetails(id): Observable<any> {
        return this.http.get(`${environment.api}subscription/view?id=${id}`);
    }
    _getSubscriptionicoachDetails(data): Observable<any> {   
        return this.http.get(`${environment.api}icoach/listEntries?`+data);
    }
    _deleteSubscription(data): Observable<any> {
        return this.http.post(`${environment.api}subscription/delete`, data);
    }
    _updatePayment(data): Observable<any> {
        return this.http.post(`${environment.api}payments/update`, data);
    }
    _getPaymentList(data): Observable<any> {
        return this.http.get(`${environment.api}payments/list?`+data);
    }
    _getPaymentDetails(id,payment): Observable<any> {
        return this.http.get(`${environment.api}payments/view?id=${id}&paymentFor=${payment}`);
    }
    _deletePayment(data): Observable<any> {
        return this.http.post(`${environment.api}payments/delete`, data);
    }
    
    //Introduction
    _getAllIntroduction(): Observable<any> {
        return this.http.get(`${environment.api}introduction/list`);
    }
   
    _getIntroductionDetails(id): Observable<any> {
        return this.http.get(`${environment.api}introduction/view?id=${id}`);
    }
    _addcreateIntroduction(data): Observable<any> {
        return this.http.post(`${environment.api}introduction/create`,data);
    }
    _editcreateIntroduction(data): Observable<any> {
        return this.http.put(`${environment.api}introduction/update`,data);
    }
    _deleteIntroduction(data): Observable<any> {
        return this.http.delete(`${environment.api}introduction/delete`, data);
    }

    //Transaction
    _getAllTransaction(data): Observable<any> { 
        console.log(data)
        return this.http.get(`${environment.api}transactions/list?`+data);
    }
}
