import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormErrorComponent } from './form-error/form-error.component';
import { InputNumberDirective }from './service/input-number.directive'
import { MomentModule } from 'ngx-moment';

@NgModule({
  imports: [CommonModule, MomentModule],
  declarations: [FormErrorComponent,InputNumberDirective],
  exports: [FormErrorComponent,InputNumberDirective]
})
export class SharedModule { }
