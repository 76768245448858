import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute  } from '@angular/router';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  selectedTab:number=0
  menuList = [ 
    { menuName: 'corporate-licensing',title: 'Corporate Licensing', path: '/corporate-licensing' },
    { menuName: 'user-management',title: 'User Management', path: '/user' },
    { menuName: 'subscription-management',title: 'Subscription Management', path: '/subscription' },
    // { menuName: 'payment',title: 'Payment', path: '/payment' },
    { menuName: 'transactions',title: 'Transactions', path: '/transaction' },
    { menuName: 'introduction',title: 'Introduction', path: '/introduction' }
  ];
  constructor(
    public router: Router,
    private activatedroute:ActivatedRoute ,
  ) { }

  ngOnInit(): void {
  }

  routeDirect(path,i): void {
    this.router.navigate([path]);
    this.selectedTab=i;
  }

}
