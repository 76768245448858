import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/authentication/authentication.module').then((m) => m.AuthenticationModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./modules/user-management/user-management.module').then((m) => m.UserManagementModule)
  },
  {
    path: 'corporate-licensing',
    loadChildren: () => import('./modules/corporate-licensing/corporate-licensing.module').then((m) => m.CorporateLicensingModule)
  },
  {
    path: 'subscription',
    loadChildren: () => import('./modules/subscription-management/subscription-management.module').then((m) => m.SubscriptionManagementModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./modules/payment/payment.module').then((m) => m.PaymentModule)
  },
  {
    path: 'transaction',
    loadChildren: () => import('./modules/transaction/transaction.module').then((m) => m.TransactionModule)
  },
  {
    path: 'introduction',
    loadChildren: () => import('./modules/introduction/introduction.module').then((m) => m.IntroductionModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
