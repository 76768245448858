<div class="side-menu">
    <div class="logo"></div>
    <ul class="menu">
        <a  title="Home"  >
            <!-- <li [ngClass]="rla.isActive ? 'activeTab' : ''"></li> -->
            <li></li>
        </a>
        <a *ngFor="let menu of menuList; let i=index" title="{{menu.title}}" routerLink="{{menu.path}}" [ngClass]="rla.isActive ? 'activeTab' : ''" routerLinkActive #rla="routerLinkActive" >
            <!-- <li [ngClass]="rla.isActive ? 'activeTab' : ''"></li> -->
            <li></li>
        </a>
    </ul>
    <div class="setting"></div>
</div>